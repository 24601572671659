<template>
    <div class="layout-task-info">
        <div class="layout-device-bread">
            <Breadcrumb>
                <BreadcrumbItem to="/workflow/task/inspection/list">安全巡检任务</BreadcrumbItem>
                <BreadcrumbItem>任务详情</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <!--s: Search 区域-->
        <div class="device-list-search">
<!--            <div class="list-back"><a href="javascript:" @click="onBack"><Icon type="ios-arrow-round-back" />返回</a></div>-->
            <div class="list-info">
                <ul>
                    <li>任务编号：{{ taskList.tasksCode }}</li>
                    <li>引用流程：{{ taskList.flowName }}</li>
                    <li>任务名称：{{ taskList.tasksName }}</li>
                    <li>执行状态：{{ taskList.status === 0 ? '未执行' : '已执行' }}</li>
                    <li>创建时间：{{ taskList.createTime }}</li>
                </ul>
            </div>
            <div class="list-title">
                <h1 class="name">执行记录</h1>
            </div>
        </div>
        <!--e: Search 区域-->
        <!--s: Data 区域-->
        <div class="device-list">
            <Table :loading="recordLoading" stripe="stripe" :columns="recordListColumns" :data="recordListArray.content" >
                <template slot="执行状态" slot-scope="{ row }">
                    {{ row.status === 0?'未完成':'已完成' }}
                </template>
                <template slot="完成时间" slot-scope="{ row }">
                    {{ row.finishTime || '/'}}
                </template>
                <template slot="操作" slot-scope="{ row }">
                    <div class="handle">
                        <a href="javascript:" @click="onQueryLink(row)">查看</a>
                        <a href="javascript:" v-if="row.status === 1" @click="onDownLoad(row)">生成报告</a>
                        <span class="handle-grey" v-if="row.status === 0">生成报告</span>
                    </div>
                </template>
            </Table>
            <div class="device-list-page" v-if="recordListArray.content && recordListArray.content.length !== 0">
                <Page :total="recordListArray.totalSize" :current="recordListArray.currentPage" show-total show-sizer show-elevator :page-size="recordListParams.displayNumber" @on-change="onSearchPage" @on-page-size-change="onSearchPageSize"/>
            </div>
        </div>
        <!--e: Data 区域-->

    </div>
</template>
<script>
import TaskInspectionInfo from './info'
export default TaskInspectionInfo
</script>
<style lang="less">
@import "info";
</style>
