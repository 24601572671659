/**
 * 工作任务安全巡检详情
 * @type {{}}
 */
import Vue from "vue";
import { Input, Icon, Select, Option, Message, Table, Page, Modal,Button,Breadcrumb,BreadcrumbItem } from "view-design";
import {
    getRecordDataService,
    getRecordListService,
    recordInfoColumns,
    recordListColumns,
} from "@/service/workflow-service";
import {mapMutations, mapState} from "vuex";
import {downloadFile} from "@/utils/tools";
Vue.component('Input', Input);
Vue.component('Icon', Icon);
Vue.component('Select', Select);
Vue.component('Option', Option);
Vue.component('Table', Table);
Vue.component('Page', Page);
Vue.component('Modal', Modal);
Vue.component('Button', Button);
Vue.component('Breadcrumb', Breadcrumb);
Vue.component('BreadcrumbItem', BreadcrumbItem);
const TaskInspectionInfo = {
    name:'TaskInspectionInfo',
    computed: {
        ...mapState({
            taskId: state => state.system.taskId, //任务id
            taskList: state => state.system.taskList, //任务数据
        }),
    },
    data() {
        return {
            recordLoading:false, //loading
            recordModalVisible:false, //图片modal
            recordModalTitle:'', //modal title
            recordInfoColumns:recordInfoColumns, //显示字段
            recordListColumns:recordListColumns,
            recordInfoArray:{}, //数据集合
            recordListArray:{},
            imageSrc:'',
            recordListParams:{ //提交的数据集
                tasksName:'',  //任务名称
                flowName:'', //引用流程
                status:'', //任务状态
                currentPage: 1,  //当前页
                displayNumber:10 // 显示条数
            }
        }
    },
    created() {
        this.recordListParams.tasksName = this.taskList.tasksName
        this.onRecordList().then()
    },
    methods: {
        ...mapMutations('system',['SET_TASK_ID','SET_TASK_LIST']),
        /**
         * 返回
         */
        onBack() {
            this.$router.push('/workflow/task/list')
        },
        /**
         * 查看跳转
         * @param row
         */
        onQueryLink(row) {
            this.SET_TASK_ID(row.id)
            this.SET_TASK_LIST(row)
            this.$router.push('/workflow/record/inspection/info')
        },
        /**
         * 下载验证
         * @param row
         */
        onDownLoad(row) {
            this.onDownLoadRecordData(row).then()
        },
        /**
         * 发送当前页
         * @param number
         */
        onSearchPage(number){
            this.recordListParams.currentPage = number
            this.onRecordList().then()
        },
        /**
         * 发送每页显示的条数
         * @param number
         */
        onSearchPageSize(number){
            this.recordListParams.displayNumber = number
            this.onRecordList().then()
        },
        /**
         * 获取执行记录列表
         */
        async onRecordList() {
            try {
                this.recordLoading = true
                this.recordListArray = {}
                let params = {
                    tasksName:this.recordListParams.tasksName,
                    flowName:this.recordListParams.flowName,
                    status:this.recordListParams.status,
                    tasksType:1,
                    size:this.recordListParams.displayNumber,
                    page:this.recordListParams.currentPage
                }
                const { code,data } = await getRecordListService(params)
                if(code === 'SUCCESS'){
                    this.recordListArray = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.recordLoading = false
            }catch (error){
                this.recordLoading = false
                console.log(error)
            }
        },
        /**
         * 下载记录数据
         */
        async onDownLoadRecordData(row) {
            try {
                this.recordLoading = true
                let params = {
                    id:row.id
                }
                const { data } = await getRecordDataService(params)
                downloadFile(row,data,'excel')
                this.recordLoading = false
            }catch (error){
                this.recordLoading = false
                console.log(error)
            }
        },
    },
    destroyed () {
    }
}
export default TaskInspectionInfo
